import axios from 'axios'


const AXIOS_URL = process.env.REACT_APP_AXIOS_URL


const axiosConfig = {
    baseURL: `${AXIOS_URL}/api/ag`,
    timeout: 30000,
}
const ag_axios = axios.create(axiosConfig)




export default ag_axios