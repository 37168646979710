import ag_axios from "../../../../axios"

export default function generateReport (reportType) {
    const request = ag_axios({
        method: 'post',
        url: 'reports/create_report',
        data: {
            'report_type': reportType
        }
    })
    return request
}
