import { Sidebar } from 'react-pro-sidebar'
import { Component } from 'react'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import BloodtypeIcon from '@mui/icons-material/Bloodtype';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { NavLink } from 'react-router-dom'
import './sidebar.css'

export default class CoolSidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: '6.7%',
      logo_class: '',
      p_class: 'p fade_in',
      is_toggled: false,
    }
  }
  
  toggleSidebar = () => {
    this.setState({ is_toggled: !this.state.is_toggled })
  }

  logout = () => {
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
    window.location.href = '/login'
  }
  render() {
    return localStorage.getItem('access') !== null ? (
      <Sidebar
        id='sidebar'
        backgroundColor='linear-gradient(180deg, #3D88FE 0%, #0D4FD2 100%)'
        width={'6.7%'}
      >
        <div>
          <div style={{ height: '20vh' }}>
            <BloodtypeIcon
              className={this.state.is_toggled ? 'logo scaled' : 'logo normal'}
              width={100}
              style={{ height: '10vh', marginTop: '4vh', fontSize: '6em', color: 'white' }}
              />
          </div>
          <div className='Nav' style={{ marginTop: '3vh', color: '#C3C1C0' }}>
            <NavLink to='/'>
              <div>
                <HomeOutlinedIcon />
                <p className={this.state.is_toggled ? 'p fade_out' : 'p fade_in'}>На главную</p>
              </div>
            </NavLink>
            <NavLink to='/reports'>
              <div>
                <AssessmentOutlinedIcon style={{ transform: 'rotate(90deg)' }} />
                <p className={this.state.is_toggled ? 'p fade_out' : 'p fade_in'}>Отчёты</p>
              </div>
            </NavLink>
            <a style={{ marginTop: '40vh', cursor: 'pointer' }} onClick={() => this.logout()}>
              <div>
                <LogoutOutlinedIcon />
              </div>
              <div style={{ marginTop: '1vh' }}>
                <p className={this.state.is_toggled ? 'p fade_out' : 'p fade_in'}>
                  {localStorage.getItem('full_name')}
                </p>
              </div>
            </a>
          </div>
        </div>
      </Sidebar>
    ) : (
      <></>
    )
  }
}
