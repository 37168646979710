import { React, useState, useEffect } from "react";
import getReportTypes from "./api/get-report-types";
import getReportGeneratingStatuses from "./api/get-report-generating-statuses";
import GenerateReportMenu from "./components/generate-report-menu";
import GeneratedReportsStatuses from "./components/generating-reports-statuses";

export default function ReportsView(props) {
  let [loading, setloading] = useState(true);

  let [reportType, setReportType] = useState();
  let [availableReportTypes, setAvailableReportTypes] = useState();

  let [generatingReports, setGeneratingReports] = useState();

  function makeGetReportGeneratingStatuses() {
    getReportGeneratingStatuses().then((res) => {
      setGeneratingReports(res.data);
    });
  }

  function getAvailableReportTypes() {
    setloading(true);

    getReportTypes().then((res) => {
      setAvailableReportTypes(res.data);
      setReportType(res.data[0].name);
      getReportGeneratingStatuses().then((res) => {
        setGeneratingReports(res.data);
        setloading(false);
      });
    });
  }

  useEffect(() => {
    getAvailableReportTypes();
    let requestTimer = setInterval(makeGetReportGeneratingStatuses, 1000);

    return () => {
      clearTimeout(requestTimer);
    };
  }, []);

  return (
    <>
      {loading && <p>Loading...</p>}
      {!loading && (
        <GenerateReportMenu
          setReportType={setReportType}
          makeGetReportGeneratingStatuses={makeGetReportGeneratingStatuses}
          reportType={reportType}
          availableReportTypes={availableReportTypes}
        />
      )}
      {loading && <p>Loading...</p>}
      {!loading && (
        <GeneratedReportsStatuses generatingReports={generatingReports} />
      )}
    </>
  );
}
