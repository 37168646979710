import ag_axios from "../../../../axios"

export default function downloadReportRequest (genetatingReportId) {
  const request = ag_axios.post(
    'reports/download_report',
    {
        'report_id': genetatingReportId,
    },
    {
      responseType: 'blob'
    }
  )
  return request
}

